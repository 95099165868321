<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/records/services"><a>Kartoteki</a></router-link>
          <router-link tag="li" to="/records/services" class="is-active"><a>{{ dictionary['table_order_is_services_field_label'] }}</a></router-link>
          <router-link tag="li" to="/records/services/add" class="is-active"><a>Dodaj</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->

    <ServicesForm />
  </section>
</template>

<script>
  import ServicesForm from '@/views/Records/Services/ServicesForm'
  import {mapGetters} from 'vuex'

  export default {
    name: 'ServicesAdd',
    components: {
      ServicesForm
    },
    computed:{
      ...mapGetters({
        dictionary: 'dictionary',
      }),
    },  
  }
</script>

<style lang="scss" scoped>

</style>